/* this file is transformed by vux-loader */
/* eslint-disable */
import "core-js/modules/es.array.push.js";
import Vue from 'vue';
import rxboxs from '@/components/common/rx-boxs';
import RxAttachment from '@/components/common/form/RxAttachment';
import router from '@/router/index';
import { RxUtil } from "@/assets/util.js";
import { convertBoJson } from '@/assets/app.js';
import sysuser from '@/components/common/SysUser';
import sysgroup from '@/components/common/SysGroup';
import Popup from "vux/src/components/popup/index.vue";
import Checklist from "vux/src/components/checklist/index.vue";
Vue.component(sysuser.name, sysuser);
Vue.component(sysgroup.name, sysgroup);
Vue.component(RxAttachment.name, RxAttachment);
export default {
  name: "rx-startflow",
  components: {
    Popup,
    Checklist
  },
  data() {
    return {
      opinion: "同意",
      files: "[]",
      bpmSolution: {},
      instId: "",
      isSinglePage: true,
      approveCmd: {},
      processConfig: {},
      paths: [],
      showUserDialog: false,
      showGroupDialog: false,
      users: "[]",
      groups: "[]",
      allowSelect: false,
      showPop: false,
      checkValue: "",
      selectJson: []
    };
  },
  mounted() {
    //			this.isSinglePage = this.$route.params.isSinglePage;
    //			var data = this.$store.state.bpmModule.instData;
    //			console.info("startflow activated:" + data)
    //			console.info(data)
    //			this.bpmSolution = data.bpmSolution;
    //			if(!data.instId) {
    //				this.instId = data.instId;
    //			}
    var data = this.$store.state.bpmModule.startFlowData;
    this.approveCmd = data.approveCmd;
    this.processConfig = data.processConfig;
    this.bpmSolution = data.bpmSolution;
    this.nodeIdUsers = data.nodeIdUsers;
    if (this.nodeIdUsers.users) {
      this.getUserJson(this.nodeIdUsers.users);
      //				this.users=users
    }

    this.listPath();
  },
  methods: {
    getUserJson(v) {
      if (!v) return "";
      var ary = JSON.parse(v);
      if (!ary) return "";
      this.selectJson = ary.map(item => {
        return {
          key: item.id,
          value: item.name
        };
      });
    },
    onChange() {
      var checkValue = this.checkValue;
      var selectJson = this.selectJson;
      var names = [];
      for (let i = 0, j = selectJson.length; i < j; i++) {
        for (let m = 0, n = checkValue.length; m < n; m++) {
          if (checkValue[m] == selectJson[i].key) {
            names.push(selectJson[i].value); //显示值
            break;
          }
        }
      }
      this.users = checkValue.toString();
      this.showUsers = names.toString();
      this.showPop = false;
    },
    onCancel() {
      this.showPop = false;
    },
    onClear() {
      this.checkValue = [];
      this.users = "";
      this.showUsers = "";
      this.showPop = false;
    },
    listPath() {
      var url = _baseUrl + "/bpm/core/bpmInst/getPath.do";
      var data = {
        solId: this.approveCmd.solId,
        jsonData: this.approveCmd.jsonData
      };
      var params = RxUtil.serialJsonToForm(data);
      this.$ajax.post(url, params).then(res => {
        if (res.status == 200) {
          this.paths = res.data;
        }
      });
    },
    changeOpinion(e) {
      var v = e.srcElement.value;
      this.opinion = v;
    },
    /**
     * 构建cmd数据。
     */
    getProcessCmd() {
      var bpmSolution = this.bpmSolution;
      var approveCmd = {};
      approveCmd.solId = bpmSolution.solId;
      approveCmd.opinion = this.opinion;
      approveCmd.isValid = true;
      approveCmd.message = "success";
      if (this.instId) {
        approveCmd.bpmInstId = this.instId;
      }
      var data = this.$store.state.bpmModule.instData.formModels;
      var jsonData = convertBoJson(data);
      var jsonStr = this.replaceUnicode(jsonData);
      approveCmd.jsonData = jsonStr;
      approveCmd.opFiles = this.files;
      return approveCmd;
    },
    /**
     * 打开用户对话框
     */
    selectUserDialog(vm) {
      this.showUserDialog = true;
      this.$refs.sysUser.search();
      // 设置选择用户。
      this.$refs.sysUser.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeUserVm = vm;
    },
    /**
     * 打开用户组对话框
     */
    selectGroupDialog(vm) {
      this.showGroupDialog = true;
      this.$refs.sysGroup.type = "group";
      this.$refs.sysGroup.search();
      // 设置选择用户。
      this.$refs.sysGroup.setValue("");
      //设置选中的rx-box 控件，用于传递数据。
      this.nodeGroupVm = vm;
    },
    /**
     * 点击用户对话框选择按钮，处理用户对话框返回数据。
     */
    selectUser(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.userId;
        o.text = obj.fullname;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeUserVm.setValue(json);
      this.showUserDialog = false;
    },
    selectGroup(data) {
      var list = [];
      for (var i = 0; i < data.length; i++) {
        var obj = data[i];
        var o = {};
        o.id = obj.groupId;
        o.text = obj.name;
        list.push(o);
      }
      var json = JSON.stringify(list);
      this.nodeGroupVm.setValue(json);
      this.showGroupDialog = false;
    },
    cancel() {
      router.go(-1);
      //				router.push({
      //					name: 'startForm',
      //					params: {
      //						solId: this.bpmSolution.solId,
      //						instId: this.instId ? this.instId : "-1"
      //					}
      //				})
    },

    startFlow() {
      var approveCmd = this.getProcessCmd();
      //获取数据失败。
      if (!approveCmd.isValid) {
        this.errorToast(approveCmd.message);
        return;
      }
      delete approveCmd.isValid;
      delete approveCmd.message;
      if (this.processConfig.startUser == 'true') {
        var objGroup = this.getIdStr(this.groups);
        // debugger;
        var user = {
          nodeId: this.nodeIdUsers.nodeId,
          userIds: this.users,
          groupIds: objGroup
        };
        var aryUser = [];
        aryUser.push(user);
        approveCmd.destNodeUsers = JSON.stringify(aryUser);
      }
      var url = _baseUrl + "/bpm/core/bpmInst/startProcess.do";
      var params = RxUtil.serialJsonToForm(approveCmd);
      this.$dialog.loading.open('正在启动流程,请稍候...');
      this.$ajax.post(url, params).then(response => {
        this.$dialog.loading.close();
        var data = response.data;
        if (data.success) {
          this.$store.commit('cleanInstData');
          this.successToast("提交成功！", 1000, 'index');
        } else {
          this.errorToast("操作失败！");
        }
      });
    },
    getIdStr(v) {
      //v:[{"text":"综合管理室","id":"452258613160158174"},{"text":"业务支持科","id":"452129987479568469"}]
      if (!v) return "";
      var ary = JSON.parse(v);
      if (!ary) return "";
      var rtn = ary.map(item => {
        return item.id;
      });
      return rtn.toString();
    }
  }
};